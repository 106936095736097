import request from '../common/request'
import { API_URL } from '../common/Url'
export default {
    getNewEstateData(params) {
        return request({
            method: 'GET',
            url: API_URL.REAESTATE_BYID_GET,
            params
        })
    },
    //获取户型
    getHouseTypes(params) {
        return request({
            method: 'GET',
            url: API_URL.HOUSE_TYPE_GET,
            params
        })
    },
    //获取楼栋
    loadModalInfo(params) {
        return request({
            method: 'GET',
            url: API_URL.NEW_ESTATE_MODEL,
            params
        })
    },
    //楼盘动态
    loadEstateDynamic(params) {
        return request({
            method: 'GET',
            url: API_URL.ESTATE_DYNAMIC,
            params
        })
    },
    //相似楼盘
    getSimilarityEstate(data) {
        return request({
            method: 'POST',
            url: API_URL.SIMILARITY_ESTATE,
            data
        })
    },
    //优选顾问
    getAgentData(data) {
        return request({
            method: 'POST',
            url: API_URL.ESTATE_AGENT,
            data
        })
    },
    //获取图片类型
    getEstateParams(params) {
        return request({
            method: 'GET',
            url: API_URL.ESTATE_PARAMS,
            params
        })
    },
    //获取户型详情
    getHouseTypeDetail(params) {
        return request({
            method: 'GET',
            url: API_URL.NEW_ESTATE_HOUSE_TYPE_DETAIL,
            params
        })
    },
    //获取户型绑定栋座
    loadBindModel(params) {
        return request({
            method: 'GET',
            url: API_URL.NEW_ESTATE_HOUSE_BIND_MODEL,
            params
        })
    },
    //获取栋座
    getBuilding(params) {
        return request({
            method: 'GET',
            url: API_URL.NEW_ESTATE_HOUSE_BINDing,
            params
        })
    },

}