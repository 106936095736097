<template>
  <div class="container">
    <h1
      class="size24 bold col4d"
      style="margin-bottom: 35px"
    >税费计算</h1>
    <div class="flex size14 sb_start">
      <ul>
        <li class="mt20 flex">
          <label class="label-title">房屋套数</label>
          <div class="label-body flex sb_center">
            <span>{{ houseNumberTitle }}</span>
            <i class="el-icon-caret-bottom size12 whiteGray"></i>
            <ul
              class="label-body-down"
              v-show="labelBodyShow"
            >
              <li
                @click.stop="handleHouseNumberItem(item)"
                v-for="item in houseNumber"
                :key="item.key"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
        </li>
        <li class="mt20 flex">
          <label class="label-title">成交价</label>
          <div class="label-body flex sb_center li-input">
            <input
              type="text"
              @input="changeDealPrice"
              v-model="dealPrice"
              maxlength="5"
              placeholder="请输入0-99999之间的数字"
            />
            <span>万元</span>
          </div>
        </li>
        <li class="mt20 flex">
          <label class="label-title">
            评估价
            <i class="el-icon-question"></i>
            <div class="tip">
              银行或评估公司对房屋的估值，并作为银行贷款的依据。评估价格不能大于成交价格。实际购房过程中，评估价与参考计税价保持一致。
            </div>
          </label>
          <div class="label-body flex sb_center li-input">
            <input
              type="text"
              name=""
              v-model="appraisalPrice"
              maxlength="5"
              @input="changeAppraisalPrice"
              placeholder="请输入少于成交价的数字"
            />
            <span>万元</span>
          </div>
        </li>
        <li class="mt20 flex">
          <label class="label-title">贷款类型</label>
          <div class="label-body flex sb_center">
            <span>{{ loanTypeTitle }}</span>
            <i class="el-icon-caret-bottom size12 whiteGray"></i>
            <ul class="label-body-down">
              <li
                v-for="item in loanType"
                @click.stop="handleLoanTypeItem(item)"
                :key="item.key"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
        </li>
        <li
          class="mt20 flex"
          v-if="isCommonLoan"
        >
          <label class="label-title">公积金金额</label>
          <div class="label-body flex sb_center li-input">
            <input
              type="text"
              name=""
              maxlength="2"
              v-model="commonLoanPrice"
              @input="changeCommonLoanPrice"
            />
            <span>万元</span>
          </div>
        </li>
        <li
          v-show="isCommonLoan"
          class="mt20 flex"
        >
          <label class="label-title">公积金年限</label>
          <div class="label-body flex sb_center">
            <span>{{ loanYearCommonTitle.title }}</span>
            <i class="el-icon-caret-bottom size12 whiteGray"></i>
            <ul class="label-body-down">
              <li
                @click.stop="changeLoanYearCommonTitle(item)"
                v-for="item in loanYear"
                :key="item.value"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
        </li>
        <li
          v-show="isCommonLoan"
          class="mt20 flex"
        >
          <label class="label-title">公积金利率</label>
          <div class="label-body flex sb_center">
            <span>{{ loanRateCommonPriceTitle.title }}</span>
            <i class="el-icon-caret-bottom size12 whiteGray"></i>
            <ul class="label-body-down">
              <li
                @click.stop="changeLoanRateCommonPrice(item)"
                v-for="item in loanRateCommonPrice"
                :key="item.value"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
        </li>
        <li
          v-show="isShopLoan"
          class="mt20 flex"
        >
          <label class="label-title">贷款比例</label>
          <div class="label-body flex sb_center">
            <span>{{ loanRatioTitle.title }}</span>
            <i class="el-icon-caret-bottom size12 whiteGray"></i>
            <ul class="label-body-down">
              <li
                @click.stop="handleLoanRatioItem(item)"
                v-for="item in loanRatio"
                :key="item.value"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
        </li>
        <li
          v-show="isCommonLoan && isShopLoan"
          class="flex"
        >
          <label class="label-title">商贷金额</label>
          <div class="label-body flex sb_center li-input">
            <input
              type="text"
              name=""
              v-model="shopLoanPrice"
              class="whiteGray"
              readonly
            />
            <span>万元</span>
          </div>
        </li>
        <li
          v-show="isShopLoan"
          class="mt20 flex"
        >
          <label class="label-title">商贷年限</label>
          <div class="label-body flex sb_center">
            <span>{{ loanYearTitle.title }}</span>
            <i class="el-icon-caret-bottom size12 whiteGray"></i>
            <ul class="label-body-down">
              <li
                @click.stop="changeLoanYearTitle(item)"
                v-for="item in loanYear"
                :key="item.value"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
        </li>
        <li
          v-show="isShopLoan"
          class="mt20 flex"
        >
          <label class="label-title">商贷利率</label>
          <div class="label-body flex sb_center">
            <span>{{ loanRateShopPriceTitle.title }}</span>
            <i class="el-icon-caret-bottom size12 whiteGray"></i>
            <ul class="label-body-down">
              <li
                @click.stop="changeLoanRateShopPrice(item)"
                v-for="item in loanRateShopPrice"
                :key="item.value"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
        </li>
        <li class="mt20 flex">
          <label class="label-title"></label>
          <div class="flex flex1">
            <el-button
              @click="onSubmit"
              class="flex1 computedButton"
              :disabled="!isCalculation"
              style="height: 45px"
            >开始计算</el-button>
          </div>
        </li>
      </ul>
      <div style="width: 218px">
        <div v-if="isComputed">
          <div style="margin: 10px auto">
            <img
              style="width: 150px; height: 150px"
              src="../assets/icon/web_icon_temp.png"
              alt=""
            />
          </div>
          <p class="center">正在计算中...</p>
        </div>
        <ul
          v-else
          style="width: 100%"
        >
          <li class="border-b flex shoufu-item sb_center">
            <span class="size14 bold">首付总计</span>
            <p class="flex">
              <span class="size20 bold green">{{ sumPay }}</span>
              <span class="size14 black bold">万元</span>
            </p>
          </li>
          <li class="flex pt10 sb_center">
            <div class="relative">
              净首付
              <i class="el-icon-question whiteGray"></i>
              <div
                class="tip"
                style="width: 250px; top: -110px"
              >
                净首付计算条件:<br />
                商业贷款:净首付=成交价-评估价*贷款<br />比例公积金贷款:净首付=成交价-公积金贷款金场
              </div>
            </div>
            <p class="flex">
              <span class="size16 bold">{{ sumPay }}</span>
              <span>万元</span>
            </p>
          </li>
          <li class="flex pt10 sb_center">
            <span class="whiteGray">税费合计</span>
            <p>
              相关税费请<a
                @click="$emit('update-show-code', true)"
                href="javascript:;"
                class="green"
              >咨询经纪人</a>
            </p>
          </li>
          <li class="pt15"></li>
          <li class="flex sb_center pt15">
            <span class="black bold">月供(等额本息)</span>
            <p class="flex">
              <span class="size20 bold green">{{
                Math.floor(monthPayInterest)
                }}</span>
              <span class="size14 black bold">元</span>
            </p>
          </li>
          <li class="flex sb_center pt15s">
            <span class="black bold">月供(等额本金)</span>
            <p class="flex">
              <span class="size20 bold green">{{
                Math.floor(monthPayMoney)
                }}</span>
              <span class="size14 black bold">元</span>
            </p>
          </li>
          <li class="pt5 size12 right whiteGray">
            每月递减{{ Math.floor(monthDecreaseMoney) }}元
          </li>
        </ul>
        <p class="size12 whiteGray pt10">
          本次计算仅作为购房参考，不能作为最终的购房依据。了解更准确的方案，建议<a>咨询经纪人</a>
        </p>
        <div class="flex flex1 mt20">
          <el-button
            @click="$emit('update-show-code', true)"
            class="flex1 tButton"
            style="height: 45px"
          >咨询首付和贷款明细</el-button>
        </div>
        <div
          class="row mt20"
          v-if="agent&&agent.guid"
        >
          <div class="avatar mr10">
            <img
              class="all"
              :src="agent.avatar||defaultAvatar"
              alt=""
            />
          </div>
          <div
            style="height: 74px"
            class="flex1 column agent-item-info size12 black mr10"
          >
            <p
              class="size17"
              style="font-weight: bold"
            >
              {{ agent.trueName }}
              <span
                class="agent-tag size12"
                tyle="font-weight: 400;"
              >新房顾问</span>
            </p>
            <div class="row mt10">
              <el-button
                v-if="agent.fixTel || agent.mobile"
                style="flex: 1; box-sizing: border-box; height: 26px"
                class="tButton chatButton greenBG size12 c_center"
              >
                <i class="el-icon-phone"></i>
                {{isSourceERP?agent.mobile:agent.fixTel || agent.mobile}}
              </el-button>
              <div style="width: 5px"></div>
              <el-button
                style="flex: 1; box-sizing: border-box; height: 26px"
                @click="$emit('update-show-code', true)"
                plain
                class="tButton chatButton greenBG green size12 c_center"
              >
                <i class="el-icon-chat-dot-round"></i>
                在线咨询
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import tool from "../utils/tool.js";
import Constant from "../common/Constant.js";
import { mapState } from "vuex";
export default {
  name: "HousePriceComputed",
  props: {
    house: {
      type: Object,
      required: true,
    },
    agent: {
      type: Object,
    },
  },
  computed: {
     ...mapState(["isSourceERP"]),
    loanRatio() {
      if (this.isFirst) {
        return [
          {
            title: "70%",
            value: 0.7,
          },
          {
            title: "65%",
            value: 0.65,
          },
          {
            title: "60%",
            value: 0.6,
          },
          {
            title: "55%",
            value: 0.55,
          },
          {
            title: "50%",
            value: 0.5,
          },
          {
            title: "45%",
            value: 0.45,
          },
          {
            title: "40%",
            value: 0.4,
          },
          {
            title: "35%",
            value: 0.35,
          },
          {
            title: "30%",
            value: 0.3,
          },
          {
            title: "25%",
            value: 0.25,
          },
          {
            title: "20%",
            value: 0.2,
          },
          {
            title: "15%",
            value: 0.15,
          },
          {
            title: "10%",
            value: 0.1,
          },
          {
            title: "5%",
            value: 0.05,
          },
        ];
      } else {
        return [
          {
            title: "50%",
            value: 0.5,
          },
          {
            title: "45%",
            value: 0.45,
          },
          {
            title: "40%",
            value: 0.4,
          },
          {
            title: "35%",
            value: 0.35,
          },
          {
            title: "30%",
            value: 0.3,
          },
          {
            title: "25%",
            value: 0.25,
          },
          {
            title: "20%",
            value: 0.2,
          },
          {
            title: "15%",
            value: 0.15,
          },
          {
            title: "10%",
            value: 0.1,
          },
          {
            title: "5%",
            value: 0.05,
          },
        ];
      }
    },
    loanYear() {
      let result = [];
      for (let i = 30; i >= 1; i--) {
        result.push({
          title: i + "年",
          value: i,
        });
      }
      return result;
    },
    isCalculation() {
      let flag =
        !this.isComputed &&
        this.dealPrice &&
        this.appraisalPrice &&
        +this.appraisalPrice < +this.dealPrice;
      if (this.isCommonLoan && this.isShopLoan) {
        flag =
          flag &&
          0 <= +this.commonLoanPrice &&
          +this.commonLoanPrice <= +this.tempPrice;
      } else if (this.isCommonLoan) {
        flag =
          flag && 0 <= +this.commonLoanPrice && +this.commonLoanPrice <= 70;
      }
      return flag;
    },
    city() {
      return this.$store.state.location;
    },
  },
  data() {
    return {
      labelBodyShow: true,
      isComputed: false,
      scale: 1.11111111,
      dealPrice: "",
      appraisalPrice: "",
      commonLoanPrice: 0,
      shopLoanPrice: 0,
      houseNumberTitle: "首套",
      loanTypeTitle: "商业贷款",
      loanRatioTitle: {
        title: "70%",
        value: 0.7,
      },
      loanYearTitle: {
        title: "30年",
        value: 30,
      },
      loanYearCommonTitle: {
        title: "30年",
        value: 30,
      },
      loanRateShopPriceTitle: {
        title: "基准利率(4.9%)",
        value: 0.049,
      },
      loanRateCommonPriceTitle: {
        title: "基准利率(3.25%)",
        value: 0.0325,
      },
      isFirst: true,
      isShopLoan: true,
      isCommonLoan: false,
      houseNumber: [
        {
          title: "首套",
          key: 1,
        },
        {
          title: "二套",
          key: 2,
        },
      ],
      loanType: [
        {
          title: "商业贷款",
          key: 1,
        },
        {
          title: "公积金贷款",
          key: 2,
        },
        {
          title: "组合贷款",
          key: 3,
        },
      ],
      loanRateShopPrice: [
        {
          title: "基准利率(4.9%)",
          value: 0.049,
        },
        {
          title: "基准利率上浮20%(5.88%)",
          value: 0.0588,
        },
        {
          title: "基准利率上浮25%(6.125%)",
          value: 0.06125,
        },
        {
          title: "基准利率上浮30%(6.37%)",
          value: 0.0637,
        },
        {
          title: "基准利率上浮35%(6.615%)",
          value: 0.06615,
        },
        {
          title: "基准利率上浮40%(6.86%)",
          value: 0.0686,
        },
      ],
      loanRateCommonPrice: [
        {
          title: "基准利率(3.25%)",
          value: 0.0325,
        },
        {
          title: "基准利率上浮10%(3.575%)",
          value: 0.03575,
        },
      ],
      sumPay: 0,
      monthPayInterest: 0,
      monthPayMoney: 0,
      monthDecreaseMoney: 0,
      tempPrice: 0,

      defaultAvatar: Constant.DEFAULT_AVATAR,
    };
  },
  methods: {
    checkValidate(value) {
      var reg = /^[1-9]\d*|0$/; // 定义正则表达式
      var flag = value.match(reg); // 规则校验
      if (flag) {
        return true;
      } else {
        return false;
      }
    },
    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    closeDown(item, target, flag = false) {
      if (flag) {
        if (item.title === target.title) {
          this.labelBodyShow = false;
        }
      } else {
        if (item.title === target) {
          // $('.label-body-down').hide()
          this.labelBodyShow = false;
          return;
        }
      }
      $(".label-body-down").hide();
      this.labelBodyShow = false;
    },

    handleHouseNumberItem(item) {
      this.closeDown(item, this.houseNumberTitle);
      this.houseNumberTitle = item.title;
      if (item.title === "首套") {
        this.isFirst = true;
      } else {
        this.isFirst = false;
      }
      this.loanRatioTitle = this.loanRatio[0];
    },
    handleLoanTypeItem(item) {
      this.closeDown(item, this.loanTypeTitle);
      this.loanTypeTitle = item.title;
      switch (item.title) {
        case "商业贷款":
          this.isShopLoan = true;
          this.isCommonLoan = false;
          break;
        case "公积金贷款":
          this.isShopLoan = false;
          this.isCommonLoan = true;
          break;
        case "组合贷款":
          this.isShopLoan = true;
          this.isCommonLoan = true;
          this.commonLoanPrice = 0;
          break;
      }
      $(".label-body-down").hide();
    },
    handleLoanRatioItem(item) {
      this.closeDown(item, this.loanTypeTitle, true);
      this.loanRatioTitle = item;
    },
    changeLoanYearTitle(item) {
      this.closeDown(item, this.loanYearTitle, true);
      this.loanYearTitle = item;
    },
    changeLoanYearCommonTitle(item) {
      this.closeDown(item, this.loanYearCommonTitle, true);
      this.loanYearCommonTitle = item;
    },
    changeLoanRateShopPrice(item) {
      this.closeDown(item, this.loanRateShopPriceTitle, true);
      this.loanRateShopPriceTitle = item;
    },
    changeLoanRateCommonPrice(item) {
      this.closeDown(item, this.loanRateCommonPriceTitle, true);
      this.loanRateCommonPriceTitle = item;
    },
    onComputed(sumPay, monthLoanRatio, sumLoanMonth) {
      let sumLoanMoney = sumPay * 10000;
      let one = monthLoanRatio * (1 + monthLoanRatio) ** sumLoanMonth;
      let two = (1 + monthLoanRatio) ** sumLoanMonth - 1;
      let monthPayInterest = (sumLoanMoney * one) / two;

      let principal = sumLoanMoney / sumLoanMonth;
      let interest = sumLoanMoney * monthLoanRatio;
      let monthPayMoney = principal + interest;
      let monthDecreaseMoney = principal * monthLoanRatio;
      return [monthPayInterest, monthPayMoney, monthDecreaseMoney];
    },
    assignment(
      monthPayInterest,
      monthPayMoney,
      monthDecreaseMoney,
      flag = false
    ) {
      if (flag) {
        this.monthPayInterest = +this.monthPayInterest + +monthPayInterest;
        this.monthPayMoney = +this.monthPayMoney + +monthPayMoney;
        this.monthDecreaseMoney =
          +this.monthDecreaseMoney + +monthDecreaseMoney;
      } else {
        this.monthPayInterest = monthPayInterest;
        this.monthPayMoney = monthPayMoney;
        this.monthDecreaseMoney = monthDecreaseMoney;
      }
    },
    onSubmit() {
      this.isComputed = true;
      if (this.isShopLoan && this.isCommonLoan) {
        this.sumPay = (
          this.dealPrice -
          this.commonLoanPrice -
          this.shopLoanPrice
        ).toFixed(1);

        let monthLoanRatioShop = this.loanRateShopPriceTitle.value / 12;
        let sumLoanMonthShop = this.loanYearTitle.value * 12;
        let monthLoanRatioCommon = this.loanRateCommonPriceTitle.value / 12;
        let sumLoanMonthCommon = this.loanYearCommonTitle.value * 12;
        {
          let [monthPayInterest, monthPayMoney, monthDecreaseMoney] =
            this.onComputed(
              this.shopLoanPrice,
              monthLoanRatioShop,
              sumLoanMonthShop
            );
          this.assignment(monthPayInterest, monthPayMoney, monthDecreaseMoney);
        }
        {
          let [monthPayInterest, monthPayMoney, monthDecreaseMoney] =
            this.onComputed(
              this.commonLoanPrice,
              monthLoanRatioCommon,
              sumLoanMonthCommon
            );

          this.assignment(
            monthPayInterest,
            monthPayMoney,
            monthDecreaseMoney,
            true
          );
        }
      } else if (this.isShopLoan) {
        this.sumPay = (
          this.dealPrice -
          this.appraisalPrice * this.loanRatioTitle.value
        ).toFixed(1);
        let monthLoanRatio = this.loanRateShopPriceTitle.value / 12;
        let sumLoanMonth = this.loanYearTitle.value * 12;
        let sumLoanMoney = this.dealPrice - this.sumPay;
        let [monthPayInterest, monthPayMoney, monthDecreaseMoney] =
          this.onComputed(sumLoanMoney, monthLoanRatio, sumLoanMonth);
        this.assignment(monthPayInterest, monthPayMoney, monthDecreaseMoney);
      } else {
        this.sumPay = this.dealPrice - this.commonLoanPrice;
        let monthLoanRatio = this.loanRateCommonPriceTitle.value / 12;
        let sumLoanMonth = this.loanYearCommonTitle.value * 12;
        let sumLoanMoney = this.dealPrice - this.sumPay;
        let [monthPayInterest, monthPayMoney, monthDecreaseMoney] =
          this.onComputed(sumLoanMoney, monthLoanRatio, sumLoanMonth);
        this.assignment(monthPayInterest, monthPayMoney, monthDecreaseMoney);
      }
      setTimeout(() => {
        this.isComputed = false;
      }, 300);
    },
    changeDealPrice: tool.throttle(function (e) {
      let value = e.target.value;
      if (!this.checkValidate(value)) {
        this.$message.error("请输入数字");
        this.dealPrice = "";
        this.appraisalPrice = "";
        return;
      }
      this.appraisalPrice = (value / this.scale).toFixed(1);
      this.shopLoanPrice = +(
        this.appraisalPrice * this.loanRatioTitle.value
      ).toFixed(1);
      this.tempPrice = this.shopLoanPrice;
    }, 300),
    changeAppraisalPrice: tool.throttle(function (e) {
      let value = e.target.value;
      if (!this.checkValidate(value)) {
        this.$message.error("请输入数字");
        this.appraisalPrice = "";
        return;
      }
      if (+value > +this.dealPrice) {
        this.$message.error("评估价不能超过成交价");
      }
    }, 300),
    changeCommonLoanPrice: tool.throttle(function (e) {
      let value = e.target.value;
      if (!this.checkValidate(value)) {
        this.$message.error("请输入数字");
        this.commonLoanPrice = "";
        return;
      }
      if (+this.commonLoanPrice > 70) {
        this.$message.error(`目前${this.city.cityName}公积金贷款金额上限70万`);
        return;
      }
      if (this.isShopLoan) {
        if (+value > this.tempPrice) {
          this.$message.error("不能超过贷款总额");
          this.shopLoanPrice = 0;
          return;
        }
        this.shopLoanPrice = +(this.tempPrice - value).toFixed(1);
      }
    }, 300),
  },

  mounted() {
    $(document).ready(function () {
      $(".label-body").click(function () {
        let diasplay = $(this).find(".label-body-down").css("display");
        if (diasplay === "block") {
          $(this).find(".label-body-down").hide();
          return;
        }
        $(".label-body-down").hide();
        $(this).find(".label-body-down").show();
      });
    });
  },
  watch: {
    house(val, oldVal) {
      // 生命周期里面的值不正确
      if (val) {
        let price;
        if (val.totalPrices) {
          price = Math.floor(val.totalPrices);
        } else {
          price = Math.floor(val.price);
        }
        console.log("watch-house-price>>>", price);
        if (price) {
          this.dealPrice = price;
          this.appraisalPrice = Math.floor(this.dealPrice / this.scale);
          this.onSubmit();
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.label-title {
  display: inline-block;
  width: 100px;
  height: 45px;
  line-height: 45px;
  color: #999;
  position: relative;
}
.el-icon-question:hover + .tip {
  display: block !important;
}
.tip {
  position: absolute;
  padding: 5px;
  color: #fff;
  background-color: #000;
  top: -125px;
  left: 0;
  width: 200px;
  box-sizing: border-box;
  z-index: 10;
  line-height: 23px;
  font-size: 12px;
  display: none !important;
}

.label-body {
  position: relative;
  padding: 12px;
  background-color: #f5f5f6;
  width: 330px;
}
.li-input.label-body {
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
}
.li-input input {
  border: none;
  outline: none;
  flex: 1;
}
.shoufu-item {
  padding: 20px 0 8px;
}
.label-body-down {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 47px;
  max-height: 220px;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  display: none;
}
.label-body-down li {
  cursor: pointer;
  padding: 12px;
}
.label-body-down li:hover {
  background-color: #f5f5f6;
}
.computedButton:hover {
  box-sizing: border-box;
  background: #fff;
  color: #00ae66;
  border: #00ae66 1px solid;

  .i {
    color: #00ae66;
  }
}
.tButton {
  color: #00ae66;
  border: #00ae66 1px solid;
  padding: 0;
}
.tButton:hover {
  color: #00ae66;
  border: #00ae66 1px solid;
  background: #fff;
}
.chatButton {
  background: #f0fef8;
  border-radius: 1px;
  border: #00ae66 1px solid;
  color: #00ae66;
  .i {
    color: #00ae66;
  }
}
.avatar {
  width: 56px;
  height: 56px;
  border-radius: 28px;
  margin-right: 15px;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
    border: 0;
    vertical-align: top;
  }
}
.agent-tag {
  padding: 0 3px;
  height: 24px;
  line-height: 24px;
  color: #889ebc;
  background-color: #f2f5f7;
  border-radius: 2px;
}
</style>