import request from '../common/request'
import { API_URL } from '../common/Url'
export default {
    userLogin(data) {
        return request({
            method: 'POST',
            url: API_URL.USER_LOGIN,
            data
        })
    },
    sendLoginCode(data) {
        return request({
            method: 'POST',
            url: API_URL.SEND_LOGIN_CODE,
            data
        })
    },

    //取消关注
    cancelHouseAttention(data) {
        return request({
            method: 'POST',
            url: API_URL.CANCEL_HOUSE_ATTENTION,
            data
        })
    },
    // 关注
    addHouseAttention(data) {
        return request({
            method: 'POST',
            url: API_URL.ADD_HOUSE_ATTENTION,
            data
        })
    },
    getCollectionStatus(guid, tradeType) {
        return request({
            method: 'GET',
            url: API_URL.GET_COLLECTION_STATUS.replace('{houseGuid}', guid).replace("{tradeType}", tradeType),
        })
    },
    pageCollection(params) {
        return request({
            method: 'GET',
            url: API_URL.COLLECTION_PAGE,
            params
        })
    },
    addViewHouse(data) {
        return request({
            method: 'POST',
            url: API_URL.BROWSING_HISTORY,
            data
        })
    },
    getRealHouseViewlog(params) {
        return request({
            method: 'GET',
            url: API_URL.BROWSING_HISTORY,
            params
        })
    },
    isLogin(params) {
        return request({
            method: 'GET',
            url: API_URL.IS_LOGIN,
            params
        })
    },
    // 发送重置密码验证码
    sendResetPasswordCode(data) {
        return request({
            method: 'POST',
            url: API_URL.XIAOZHI_RESETPASS_SEND_CODE,
            data,
        });
    },
    //小智客户端重置密码
    resetPassword(data) {
        return request({
            method: 'POST',
            url: API_URL.RESET_PASSWORD,
            data,
        });
    },
}