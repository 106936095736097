<template>
  <div v-if="showRiskTip" class="modal">
    <div class="container relative">
      <div
        @click="$emit('update-show-risk-tip', false)"
        class="close-btn cursor"
      >
        <i class="el-icon-close white size30"></i>
      </div>
      <div class="header black">
        <span>风险提示</span>
      </div>
      <div class="footer">
        本站旨在为广大经纪人提供更丰富的信息，但由于部分信息由第三方提供，部分信息通过技术手段生成，我们持续通过技术和管理手段提升信息的准确度，但我们无法确保信息的真实性、准确性和完整性。房产交易滋事体大，本站信息不应作为您交易决策的依据，您决策前应与房地产开发商核实相关信息、并亲自到房屋中核验信息，或以产权证明、政府类网站发布的官方信息为准。本站不对您交易过程中对本网站信息产生的依赖承担任何明示或默示的担保责任或任何责任。请您详细阅读如下声明：
        <br />
        <br />
        <b>请您详细阅读如下声明：</b>
        <br />
        <br />

        <p>
          1、 关于参考户型图
          <br />
          <br />
          本网呈现的户型图为第三方提供的非标准的参考户型图或根据第三方提供的户型图通过系统/技术等手段生成的非标准的参考户型图,其中户型结构及房屋面积并非按国家标准进行的测绘专业活动取得。因为各种原因，参考户型图与真实现状一定存在差异，我们无法保障户型图准确性和差异率，户型图仅供参考，不应作为您交易的决策依据，房屋面积的准确信息请您与开发商,产权方核实，并请以产权证明或您委托的专业机构测量结果为准。
        </p>
        <br />
        <p>
          2、 关于房屋装修情况
          <br />
          <br />
          本网房源图片、VR效果图、视频等呈现出的房屋装修情况可能因为拍摄时间、拍摄角度等原因和实际场景存在出入，仅供参考，不应作为您交易的决策依据，请以您在看房时房源的实际情况为准。样板间与实际交付标准可能不一致，请您审慎评估决断。
        </p>
        <br />
        <p>
          3、 关于房屋情况
          <br />
          <br />
          本网展示的房源信息（包括但不限于房屋面积、所在楼栋、房屋用途、开盘时间等）由开发商或其他第三方提供，仅供参考不应作为您交易的决策依据，房源的准确信息请您与开发商核实，并以房本信息、房屋实际情况、房屋交易合同中披露的信息为准。
        </p>
        <br />
        <p>
          4、 关于房屋周边配套等
          <br />
          <br />
          房源介绍中的周边配套、在建设施、规划设施、地铁信息、绿化率、得房率等内容均系开发商等第三方提供，仅供参考，不应作为交易的决策依据，房屋周边配套请您与开发商及主管部门核实，并以房本信息、房屋实际情况、房屋交易合同中披露的信息为准。
        </p>
        <br />
        <p>
          5、 关于距离
          <br />
          <br />
          房源介绍中与距离相关的数据均来源于百度地图。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RiskTip",
  props: {
    showRiskTip: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped>
.container {
  width: 640px;
  margin: 0px auto;
  background: #fff;
  border-radius: 10px;
  /* overflow: auto; */
  height: 80vh;

  /* max-height: 660px; */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.header {
  height: 156px;
  width: 100%;
  background: url("../assets/imgs/web_house_tip_bg.png") no-repeat;
  background-size: 100% 100%;
  font-size: 48px;
  padding: 45px 40px;
  box-sizing: border-box;
}
.footer {
  height: calc(100% - 156px);
  padding: 35px 47px;
  box-sizing: border-box;
  background-color: #fff;
  overflow-y: auto;
}
.close-btn {
  position: absolute;
  top: -15px;
  right: -50px;
  z-index: 10;
}
</style>